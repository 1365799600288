<template>
  <div class="header-container">
    <!-- <van-nav-bar title="广软疫宝" fixed="true">
      <template #right>
        <van-icon name="search" size="18" />
      </template>
    </van-nav-bar> -->
    <div class="panel">
      <div class="title">
        <div class="p-header">热门服务</div>
      </div>
      <!-- <div class="underline"></div> -->
      <div class="ports">
        <div class="inport">
          <router-link to="/vaccine">
            <div class="logo">
              <img src="../../../assets/svg/疫苗.svg" alt="" />
            </div>
            疫苗预约
          </router-link>
        </div>
        <div class="inport">
          <router-link to="/acid">
            <div class="logo">
              <img src="../../../assets/svg/体温计.svg" alt="" />
            </div>
            核酸预约
          </router-link>
        </div>
        <div class="inport">
          <router-link to="/outpatient">
            <div class="logo">
              <img src="../../../assets/svg/医院.svg" alt="" />
            </div>
            门诊预约
          </router-link>
        </div>
        <div class="inport">
          <router-link to="/prevent">
            <div class="logo">
              <img src="../../../assets/svg/口罩.svg" alt="" />
            </div>
            防疫科普
          </router-link>
        </div>
        <div class="inport">
          <router-link to="/checkself">
            <div class="logo">
              <img src="../../../assets/svg/健康.svg" alt="" />
            </div>
            健康自查
          </router-link>
        </div>
        <div class="inport">
          <router-link to="/riskarea">
            <div class="logo">
              <img src="../../../assets/svg/交通管制.svg" alt="" />
            </div>
            风险区域
          </router-link>
        </div>
      </div>
      <!-- <div> -->
      <!-- </div> -->
      <!-- <div>
        <div></div>
        <div></div>
        <div></div>
      </div> -->
    </div>
    <van-dialog
      v-model="show"
      theme="round-button"
      title="程序说明"
      confirm-button-text="知道了"
      confirm-button-color="linear-gradient( 135deg, #5EFCE8 10%, #736EFE 100%)"
      @confirm="close"
    >
      <!-- <p class="tips"> -->
      <div class="tips">
        <div>1. 本程序仅作展示用，无涉及用户隐私数据。</div>
        <div>
          2.
          疫情相关数据为实时数据，来源于网络api，有请求次数限制，因此可能出现次数不足无法获取的情况。
        </div>
        <div>3. 个人中心部分功能尚未完善。</div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  props: {},
  data() {
    //这里存放数据
    return {
      show: false
    }
  },
  //计算属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    tip() {
      // console.log('使用说明：')
      // console.log('    本程序仅作展示用，承诺绝无涉及用户实际信息。')
      // console.log('数据说明：')
      // console.log(
      //   '    大部分为模拟数据，定位、疫情大数据、疫情热点、风险区域查询、健康新闻为真实的实时数据。'
      // )
      // console.log('bug说明：')
      // console.log(
      //   '    由于大部分为网络接口，且为收费接口，可能会由于请求次数达到上限而无法得到数据响应，页面无内容渲染的情况。'
      // )
      // console.log('部分功能说明：')
      // console.log('    个人中心大部分功能未完成，首页功能已完善。')
      // console.log('表单提交说明：')
      // console.log(
      //   '    用户填写数据皆为页面静态数据，不会发送到服务器，请放心输入，仅作模拟成功的提示。'
      // )
      // console.log('备注：')
      // console.log('    如果你有好的建议，欢迎提出。')
      // console.log('仓库地址：')
      // console.log('    https://github.com/Xilin05/yibao/tree/master')
    },

    dialogShow() {
      let signal = window.sessionStorage.getItem('showDialog')
      console.log(signal)
      if (signal == '0') {
        this.show = false
      } else {
        this.show = true
      }
    },
    close() {
      this.show = false
      window.sessionStorage.setItem('showDialog', 0)
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.tip()
    this.dialogShow()
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {}
}
</script>
<style lang="scss" scoped>
.header-container {
  position: relative;
  width: 100%;
  height: 23rem;
  // background: url("../../../assets/img/toutu.png") no-repeat;
  background: url('../../../assets/img/首页插图2.png') no-repeat;
  background-size: 100% auto;
  background-position: 0% 0%;
  .panel {
    font-size: 0.8rem;
    // font-weight: bold;
    color: rgb(49, 49, 49);
    width: 90%;
    height: 15.2rem;
    border-radius: 1rem;
    overflow: hidden;
    position: absolute;
    bottom: 0rem;
    left: 0rem;
    right: 0rem;
    margin: auto;
    background-color: #ffffff;
    box-shadow: 0px 0px 8px 0px #ebebeb;
    .title {
      padding: 10px 0px;
      .p-header {
        color: rgb(87, 135, 255);
        font-size: 1.2rem;
        font-weight: bold;
        width: 100%;
        // height: 1.8rem;
        // line-height: 2rem;
        text-indent: 2rem;
        // margin-top: 0.2rem;
      }
    }
    .underline {
      width: 2rem;
      height: 3px;
      background-color: rgb(87, 135, 255);
      margin: 0rem 0rem 0.7rem 2rem;
    }
    .ports {
      width: 100%;
      height: 11.7rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      align-items: center;
      padding-top: 5px;
      border-top: 1px solid rgb(240, 240, 240);
      .inport {
        width: 30%;
        // height: 45%;
        display: flex;
        flex-direction: column;
        text-align: center;
        a {
          color: rgb(49, 49, 49);
          .logo {
            width: 3rem;
            height: 3rem;
            // line-height: 3rem;
            padding: 8px;
            // background-image: linear-gradient();
            // border: 2px solid rgba(138, 165, 255, 0.103);
            border-radius: 1.3rem;
            margin: 0px auto 5px auto;
            display: flex;
            align-items: center;
            box-shadow: 3px 3px 7px rgba(49, 49, 49, 0.1),
              -3px -3px 7px rgb(255, 255, 255);
            background-color: #fafafa;
            img {
              width: 90%;
              height: auto;
              margin: 0 auto;
            }
          }
        }
      }
    }
  }
  .tips {
    padding: 10px 12px;
    font-size: 1rem;
    color: rgb(73, 73, 73);
    div {
      text-indent: 1rem;
    }
  }
}
</style>
